<template>
	<div id="couponEdit">
		<page-coupon-edit :is-shop="0"></page-coupon-edit>
	</div>
</template>

<script>
import PageCouponEdit from '@/components/layout/coupon/page-coupon-edit.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{		
	components:{
		PageCouponEdit
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/marketing/couponList'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style>

</style>
